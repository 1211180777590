<template>
  <div>
    <b-table
      :data="contacts"
      :narrowed="true"
      striped
    >
      <b-table-column
        v-slot="props"
        custom-key="avatar"
        centered
      >
        <figure class="image is-32x32">
          <img
            style="width:32px; height:32px;"
            class="is-rounded"
            :src="props.row.photo ? props.row.photo : defaultImage"
            :alt="props.row.firstName"
          >
        </figure>
      </b-table-column>

      <b-table-column
        label="Name"
        field="firstName"
        sortable
        searchable
      >
        <template
          slot="searchable"
          slot-scope="props"
        >
          <b-input
            v-model="props.filters['firstName']"
            size="is-small"
            placeholder="Search..."
            icon="search"
          />
        </template>

        <template v-slot="props">
          <router-link
            :to="{ name: 'ViewContact', params: { contactId: props.row.contactId} }"
            class="has-text-weight-semibold"
          >
            {{ props.row.firstName }} {{ props.row.lastName }}
          </router-link>
        </template>
      </b-table-column>

      <b-table-column
        v-slot="props"
        label="Email"
        field="emailAddress"
      >
        <a
          :href="`mailto:${props.row.emailAddress}`"
          class="has-text-info"
        >
          {{ props.row.emailAddress }}
        </a>
      </b-table-column>

      <b-table-column
        v-slot="props"
        label="Phone"
        field="phone"
      >
        {{ props.row.phone }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        custom-key="actions"
      >
        <div
          v-if="hasRole('ROLE_BUSINESS_ADMIN') || isContactOwner(props.row.ownerId)"
          class="buttons"
        >
          <b-tooltip
            type="is-info"
            label="Edit"
            position="is-bottom"
          >
            <b-button
              tag="router-link"
              :to="{
                name: 'EditContact',
                params: { contactId: props.row.contactId}
              }"
              type="is-text"
              class="has-text-grey"
              icon-right="edit"
            />
          </b-tooltip>

          <b-tooltip
            type="is-info"
            label="mail"
            position="is-bottom"
          >
            <a
              :href="`mailto:${props.row.emailAddress}`"
              class="has-text-grey"
            >
              <b-icon icon="envelope" />
            </a>
          </b-tooltip>

          <b-tooltip
            v-if="hasPermissions('create_events')"
            type="is-info"
            label="Event"
            position="is-bottom"
          >
            <b-button
              type="is-text"
              class="has-text-grey"
              icon-right="clock"
              @click="$emit('open-events-modal', props.row.contactId)"
            />
          </b-tooltip>

          <b-tooltip
            v-if="hasPermissions('delete_contacts')"
            type="is-info"
            label="Delete"
            position="is-bottom"
          >
            <b-button
              type="is-text"
              class="has-text-grey"
              icon-right="trash"
              @click="$emit('delete-contact', props.row.contactId)"
            />
          </b-tooltip>
        </div>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import defaultImage from '@/assets/images/avatar.svg';

export default {

  name: 'ContactsTable',

  props: {
    contacts: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    defaultImage,
  }),

};
</script>

<style lang="css" scoped>
</style>
