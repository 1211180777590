<template>
  <b-table
    :data="activities"
    :narrowed="true"
    striped
  >
    <b-table-column
      label="Name"
      field="activityName"
      sortable
    >
      <template
        slot="searchable"
        slot-scope="props"
      >
        <b-input
          v-model="props.filters['activityName']"
          placeholder="Search..."
          icon="search"
        />
      </template>

      <template v-slot="props">
        {{ props.row.activityName }}
      </template>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Description"
      field="description"
    >
      <p>{{ props.row.description }}</p>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Meeting Date"
      field="meetingDate"
      :visible="meetingDate"
    >
      <template v-if="props.row.meetingDate">
        <p>Date: {{ new Date(props.row.meetingDate).toLocaleDateString() }}</p>
        <b-tag>
          {{
            new Date(`${props.row.meetingDate}T${props.row.meetingStartTime}`)
              .toLocaleTimeString()
          }} - {{
            new Date(`${props.row.meetingDate}T${props.row.meetingEndTime}`).toLocaleTimeString()
          }}
        </b-tag>
      </template>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Due Date"
      field="dueDate"
      :visible="dueDate"
    >
      {{ props.row.dueDate ? new Date(props.row.dueDate).toLocaleDateString() : '' }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Complete"
      field="isComplete"
      centered
    >
      <b-checkbox
        v-if="props.row.isComplete"
        :value="props.row.isComplete"
        type="is-grey"
      />
    </b-table-column>

    <b-table-column
      v-slot="props"
      custom-key="actions"
    >
      <b-tooltip
        type="is-info"
        label="Edit"
        position="is-bottom"
      >
        <b-button
          type="is-text"
          class="has-text-grey"
          icon-right="edit"
          @click="$router.push(`/activities/${props.row.activityId}/edit`)"
        />
      </b-tooltip>

      <b-tooltip
        type="is-info"
        label="Notification"
        position="is-bottom"
      >
        <b-button
          type="is-text"
          class="has-text-grey"
          icon-right="bell"
          @click="$router.push(`/activities/${props.row.activityId}/edit`)"
        />
      </b-tooltip>
    </b-table-column>
  </b-table>
</template>

<script>
export default {
  name: 'AccountsTable',

  filters: {
    date(value) {
      return new Date(value).toLocaleDateString();
    },
  },

  props: {
    activities: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="css" scoped>
</style>
