<template>
  <b-table
    :data="accounts"
    :narrowed="true"
    striped
  >
    <b-table-column
      v-slot="props"
      label="Name"
      field="name"
    >
      <router-link
        :to="{ name: 'ViewAccount', params: { accountId: props.row.accountId }}"
        class="has-text-weight-bold"
      >
        {{ props.row.name }}
      </router-link>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Description"
      field="description"
    >
      {{ props.row.description }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Phone"
      field="phone"
    >
      {{ props.row.phone }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Website"
      field="website"
    >
      {{ props.row.website }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Status"
      field="isActive"
    >
      <b-icon
        v-if="props.row.isActive"
        icon="circle"
        :type="props.row.isActive ? 'is-success': ''"
      />
    </b-table-column>

    <b-table-column
      v-slot="props"
      custom-key="actions"
    >
      <div
        v-if="hasRole('ROLE_BUSINESS_ADMIN') || checkOwnership(props.row)"
        class="buttons"
      >
        <b-tooltip
          type="is-info"
          label="Opportunities"
          position="is-bottom"
        >
          <b-button
            type="is-text"
            class="has-text-grey"
            icon-right="bullseye"
            @click="$router.push(`/accounts/${props.row.id}/view?activeTab=2`)"
          />
        </b-tooltip>

        <b-tooltip
          type="is-info"
          label="Leads"
          position="is-bottom"
        >
          <b-button
            type="is-text"
            class="has-text-grey"
            icon-right="star"
            @click="$router.push(`/accounts/${props.row.id}/view?activeTab=3`)"
          />
        </b-tooltip>

        <b-tooltip
          type="is-info"
          label="Edit Account"
          position="is-bottom"
        >
          <b-button
            tag="router-link"
            :to="{
              name: 'EditAccount',
              params: { accountId: props.row.accountId}
            }"
            type="is-text"
            class="has-text-grey"
            icon-right="edit"
          />
        </b-tooltip>
      </div>
    </b-table-column>
  </b-table>
</template>

<script>
export default {
  name: 'AccountsTable',

  props: {
    accounts: {
      type: Array,
      required: true,
    },
  },

  methods: {
    /**
     * checkOwnership
     *
     * check if user is account owner
     * @params { Object } account
     */
    checkOwnership(account) {
      if (account.owners.length > 0) {
        const ownerId = account.owners[0] ? account.owners[0].owner.id : '';
        const coOwnerId = account.owners[1] ? account.owners[1].owner.id : '';
        return this.isAccountOwner(ownerId, coOwnerId, account.createdBy)
        || this.isManager(ownerId, coOwnerId);
      }

      return this.isAccountOwner(0, 0, account.createdBy);
    },
  },
};
</script>

<style lang="css" scoped>
</style>
