<template>
  <b-table
    :data="leads"
    :narrowed="true"
    striped
  >
    <b-table-column
      v-slot="props"
      label="Actions"
      custom-key="actions"
    >
      <div
        v-if="props.row.stage"
        class="buttons"
      >
        <b-tooltip
          v-if="props.row.stage.code.startsWith('open')"
          type="is-info"
          label="Edit"
          position="is-bottom"
        >
          <b-button
            type="is-text"
            class="has-text-grey"
            icon-right="edit"
            tag="router-link"
            :to="{ name: 'EditLead', params: { leadId: props.row.opportunityId } }"
          />
        </b-tooltip>

        <b-tooltip
          v-if="props.row.stage.code.startsWith('open')"
          type="is-info"
          label="Convert"
          position="is-bottom"
        >
          <b-button
            type="is-text"
            class="has-text-grey"
            icon-right="bullseye"
            @click="$emit('convert-to-opportunity', props.row.opportunityId)"
          />
        </b-tooltip>

        <b-tooltip
          v-if="props.row.stage.code.startsWith('open')"
          type="is-info"
          label="Add Activity"
          position="is-bottom"
        >
          <b-dropdown
            aria-role="list"
          >
            <b-button
              slot="trigger"
              type="is-text"
              class="has-text-grey"
              icon-right="plus"
            />

            <div
              v-for="(activityType, index) in activityTypes"
              :key="index"
            >
              <b-dropdown-item
                aria-role="listitem"
                class="has-text-grey"
                @click="$router.push(
                  `/activities/add?leadId=${props.row.opportunityId}&lead=${props.row.name}`
                )"
              >
                <b-icon
                  v-if="activityType.value === 'Task'"
                  icon="stopwatch"
                />
                <b-icon
                  v-if="activityType.value === 'Note'"
                  icon="sticky-note"
                />
                <b-icon
                  v-if="activityType.value === 'Event'"
                  icon="calendar"
                />
                <span class="has-text-weight-semibold">{{ activityType.value }}</span>
              </b-dropdown-item>

              <hr
                class="dropdown-divider"
              >
            </div>
          </b-dropdown>
        </b-tooltip>

        <b-tooltip
          type="is-info"
          label="Email"
          position="is-bottom"
        >
          <b-button
            type="is-text"
            class="has-text-grey"
            icon-right="envelope"
          />
        </b-tooltip>
      </div>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Name"
      field="name"
    >
      <router-link
        :to="{ name: 'ViewLead', params: { leadId: props.row.opportunityId } }"
        class="has-text-weight-semibold"
      >
        {{ props.row.name }}
      </router-link>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Account"
      custom-key="account"
    >
      <router-link
        :to="{name: 'ViewAccount', params: {accountId: props.row.account.id}}"
        class="has-text-weight-semibold has-text-info"
      >
        {{
          Object.keys(props.row.account).includes('name') ?
            $options.filters.capitalize(props.row.account.name) : ''
        }}
      </router-link>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Contact"
      custom-key="primaryContact"
      :visible="contact"
    >
      <span
        v-if="props.row.primaryContact"
      >
        {{
          Object.keys(props.row.primaryContact).includes('firstName') ?
            props.row.primaryContact.firstName : ''
        }}
        {{
          Object.keys(props.row.primaryContact).includes('lastName') ?
            props.row.primaryContact.lastName : ''
        }}
      </span>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Stage"
      field="stage"
      :visible="stage"
    >
      <span
        v-if="props.row.stage"
        class="has-text-bold"
      >
        {{ props.row.stage.value }}
      </span>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Estimated Revenue"
      custom-key="estimatedRevenue"
    >
      {{ props.row.estimatedRevenue }}
    </b-table-column>
  </b-table>
</template>

<script>
export default {
  name: 'LeadsTable',

  props: {
    leads: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="css" scoped>
</style>
